<div class="page-container">
    <div mat-dialog-title>
        <div class="title-container">
            <p class="title">{{ "actions.change_pwd" | translate }}</p>
        </div>
    </div>
    <mat-dialog-content>
        <form [formGroup]="form_password" (ngSubmit)="onSubmit()">
            <div class="container-fluid">
                <app-input-field
                    [label]="'actions.new_password' | translate"
                    [required]="true"
                >
                    <mat-form-field
                        appearance="outline"
                        class="default-input full-width"
                    >
                        <input
                            formControlName="newPassword"
                            matInput
                            [type]="showPwd ? 'text' : 'password'"
                        />
                        <mat-icon
                            matIconSuffix
                            (click)="toggleShowPassword()"
                            >{{
                                !showPwd ? "visibility_off" : "visibility"
                            }}</mat-icon
                        >
                        <mat-error
                            *ngIf="
                                form_password
                                    .get('newPassword')
                                    .hasError('minlength')
                            "
                        >
                            {{
                                "actions.password_must_be_at_least" | translate
                            }}
                        </mat-error>
                        <mat-error
                            *ngIf="
                                form_password
                                    .get('newPassword')
                                    .hasError('required')
                            "
                        >
                            {{ "actions.password_required" | translate }}
                        </mat-error>
                    </mat-form-field>
                </app-input-field>
                <app-input-field
                    [required]="true"
                    [label]="'actions.confirm_password' | translate"
                >
                    <mat-form-field
                        appearance="outline"
                        class="default-input full-width"
                    >
                        <input
                            formControlName="confirmPassword"
                            matInput
                            [type]="showconfirmPwd ? 'text' : 'password'"
                        />
                        <mat-icon
                            matIconSuffix
                            (click)="toggleShowconfirmPassword()"
                            >{{
                                !showconfirmPwd
                                    ? "visibility_off"
                                    : "visibility"
                            }}</mat-icon
                        >
                        <mat-error
                            *ngIf="
                                form_password
                                    .get('confirmPassword')
                                    .hasError('minlength')
                            "
                        >
                            {{
                                "actions.password_must_be_at_least" | translate
                            }}
                        </mat-error>
                        <mat-error
                            *ngIf="
                                form_password
                                    .get('confirmPassword')
                                    .hasError('mustMatch')
                            "
                        >
                            {{ "actions.password_not_match" | translate }}
                        </mat-error>
                        <mat-error
                            *ngIf="
                                form_password
                                    .get('newPassword')
                                    .hasError('required')
                            "
                        >
                            {{ "actions.password_required" | translate }}
                        </mat-error>
                    </mat-form-field>
                </app-input-field>
            </div>
        </form>
    </mat-dialog-content>
    <div mat-dialog-actions>
        <button
            type="button"
            mat-stroked-button
            class="default-btn rounded"
            (click)="closeDialog()"
            color="primary"
        >
            <mat-icon>close</mat-icon>
            <span> {{ "actions.delete_dialog.cancel" | translate }}</span>
        </button>
        <button
            type="button"
            color="primary"
            mat-flat-button
            class="default-btn rounded"
            (click)="onSubmit()"
        >
            <mat-icon>check</mat-icon>
            <span>{{ "actions.submit" | translate }}</span>
        </button>
    </div>
</div>
